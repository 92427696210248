import React, { useEffect, createContext } from 'react';
import appConfigs from 'appConfigs';

import { getListApi } from '@App/Services/api';
import {
  getLastScrollPosition,
  setLastScrollPosition,
} from '@App/Utils/storage';
import ContactSection from '@App/Containers/Main/sections/ContactSection';
import Footer from '@App/Components/Footer';
import HomeSection from '@App/Containers/Main/sections/HomeSection';
import InnovationSection from '@App/Containers/Main/sections/InnovationSection';
import IntelligenceSection from '@App/Containers/Main/sections/IntelligenceSection';
import Layout from '@App/Components/Layout';
import NavigationBar from '@App/Components/NavigationBar';
import {
  StorySection,
  MethodSection,
} from '@App/Containers/Main/sections/MethodAndStorySection';
import Section from '@App/Models/Section';
import sectionsId from '@App/Constants/sections';
import SectionTypes from '@App/Constants/sectionTypes';

import styles from './main.module.scss';

const sectionConfigs = [
  {
    id: sectionsId.homeSection,
    component: <HomeSection />,
    className: styles['home-section'],
  },
  {
    id: sectionsId.storySection,
    component: <StorySection />,
    className: styles['story-section'],
  },
  {
    id: sectionsId.innovationSection,
    component: <InnovationSection />,
    className: styles['innovation-section'],
  },
  {
    id: sectionsId.intelligenceSection,
    component: <IntelligenceSection />,
    className: styles['intelligence-section'],
  },
  {
    id: sectionsId.methodSection,
    component: <MethodSection />,
    className: styles['method-section'],
  },
  {
    id: sectionsId.contactSection,
    component: <ContactSection />,
    className: styles['contact-section'],
  },
];

interface Sections {
  innovations: Section[];
  intelligences: Section[];
  methods: Section[];
  stories: Section[];
}
const defaultSections = {
  innovations: [],
  intelligences: [],
  methods: [],
  stories: [],
};
const { controllers } = appConfigs.endpoint;

export const SectionContext = createContext<Sections>(defaultSections);

const Main: React.FC = () => {
  const [sections, updateSections] = React.useState<Sections>(defaultSections);

  useEffect((): (() => void) => {
    getListApi<Section>(controllers.sections, { limit: 20 }).then(
      (data): void => {
        updateSections({
          innovations: data
            .filter((d): boolean => d.type === SectionTypes.Innovation)
            .slice(0, 6),

          intelligences: data
            .filter((d): boolean => d.type === SectionTypes.Intelligence)
            .slice(0, 8),

          methods: data
            .filter((d): boolean => d.type === SectionTypes.Method)
            .slice(0, 3),

          stories: data
            .filter((d): boolean => d.type === SectionTypes.Story)
            .slice(0, 3),
        });
      }
    );

    const reloadListener = (): void => {
      setLastScrollPosition();
    };
    window.addEventListener('unload', reloadListener);

    return (): void => {
      window.removeEventListener('unload', reloadListener);
    };
  }, []);

  useEffect((): void => {
    if (sections !== defaultSections) {
      window.scrollTo(0, getLastScrollPosition());
    }
  }, [sections]);

  return (
    <React.Fragment>
      <NavigationBar />
      <div className={styles.wrapper}>
        <SectionContext.Provider value={sections}>
          {sectionConfigs.map(
            (sec): React.ReactChild => (
              <Layout key={sec.id} className={sec.className}>
                {sec.component}
              </Layout>
            )
          )}
        </SectionContext.Provider>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Main;
