import React, { ReactElement, Suspense, ComponentType } from 'react';

import styles from './lazyLoading.module.scss';

const lazyLoading = (Component: ComponentType): ComponentType => {
  return (props): ReactElement => (
    <Suspense fallback={<div className={styles.loader} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default lazyLoading;
