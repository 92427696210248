export const isMicrosoftBrowser = (): boolean => {
  const { userAgent } = window.navigator;
  const userAgentPatterns = ['MSIE ', 'Trident/', 'Edge/'];

  return new RegExp(userAgentPatterns.join('|')).test(userAgent);
};

export const isSafari = (): boolean =>
  navigator.userAgent.toLowerCase().indexOf('safari/') > -1 &&
  navigator.userAgent.toLowerCase().indexOf('chrome') < 0;
