import React from 'react';
import { sanitize } from 'dompurify';

interface Props {
  rawHTML: string;
}

const StaticHTML: React.FC<Props> = ({ rawHTML }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: sanitize(rawHTML) }} />
);

export default StaticHTML;
