import React from 'react';

import styles from './buttonHamberger.module.scss';

interface Props {
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const ButtonHamberger: React.FC<Props> = ({
  active = false,
  onClick = (): void => {},
  className = '',
}) => (
  <div
    role="presentation"
    onClick={onClick}
    className={`${styles.wrapper} ${className} ${active ? styles.active : ''}`}
  >
    <span className={styles.line} />
  </div>
);

export default ButtonHamberger;
