import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import ButtonNextSection from '@App/Components/ButtonNextSection';
import Title from '@App/Components/Title';
import sectionsId from '@App/Constants/sections';

import Cards from './IntelligenceCards';

interface Props {
  intl: InjectedIntl;
}

const IntelligenceSection: React.FC<Props> = ({ intl }) => (
  <section id={sectionsId.intelligenceSection}>
    <Title
      title={intl.formatMessage({ id: 'navbar.intelligence' })}
      colorReverse
    />

    <Cards />

    <ButtonNextSection
      title={intl.formatMessage({ id: 'navbar.method' })}
      idNextSection={sectionsId.methodSection}
      colorReverse
    />
  </section>
);

export default injectIntl(IntelligenceSection);
