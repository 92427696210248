import { isMicrosoftBrowser, isSafari } from './detectBrowser';

const defaultScrollOffset = 60;
const defaultScrollOptions: ScrollOptions = { behavior: 'smooth' };

const getElementPosition = (id: string): number | null => {
  const element = document.getElementById(id);

  if (!element) {
    return null;
  }

  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;

  return elementRect - bodyRect;
};

const smoothScrollForOldBrowsers = (offsetPosition: number): void => {
  const { pageYOffset } = window;
  const distance = Math.max(0, offsetPosition) - pageYOffset;
  const startTime = new Date().getTime();
  const defaultDuration = 999;
  const duration = Math.min(Math.abs(distance), defaultDuration);

  const timer = setInterval((): void => {
    const speed = Math.min(1, (new Date().getTime() - startTime) / duration);
    const nextPosition = Math.max(
      0,
      Math.floor(
        pageYOffset +
          distance *
            (speed < 0.5 ? 2 * speed ** 2 : speed * (4 - speed * 2) - 1)
      )
    );

    window.scrollTo(0, nextPosition);
    Math.floor(offsetPosition) === nextPosition && clearInterval(timer);
  }, 10);
};

const scrollToElement = (
  id: string,
  offset: number = defaultScrollOffset,
  options: ScrollOptions = defaultScrollOptions
): void => {
  const elementPosition = getElementPosition(id);

  if (elementPosition === null) {
    return;
  }

  const maxScrollOffset =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight -
    1;
  const offsetPosition = Math.min(elementPosition - offset, maxScrollOffset);

  if (isMicrosoftBrowser() || isSafari()) {
    smoothScrollForOldBrowsers(offsetPosition);
  } else {
    window.scrollTo({
      ...options,
      top: offsetPosition,
    });
  }
};

export { getElementPosition, scrollToElement };
