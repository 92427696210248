import React from 'react';

import styles from './title.module.scss';

interface Props {
  title: string;
  colorReverse?: boolean;
  children?: React.ReactChild;
  className?: string;
}

const Title: React.FC<Props> = ({
  title,
  colorReverse,
  children,
  className = '',
}) => (
  <div className={`${styles.wrapper} ${className}`}>
    <span className={`${styles.title} ${colorReverse ? styles.reverse : ''} `}>
      {title}
    </span>
    {children}
  </div>
);

export default Title;
