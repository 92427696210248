import React from 'react';

import StaticHTML from './StaticHTML';
import styles from './descriptions.module.scss';

interface Props {
  title: string;
  description: string;
  onClose?: () => void;
  className?: string;
}

const Descriptions: React.FC<Props> = ({
  title,
  description,
  onClose = (): void => {},
  className = '',
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const onButtonClicked = (): void => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }

    onClose();
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.header}>
        <span className={styles.title}>
          {title}
          <span
            role="presentation"
            className={`close-button ${styles['custom-button']}`}
            onClick={onButtonClicked}
          />
        </span>
      </div>

      <div ref={ref} className={styles.description}>
        <StaticHTML rawHTML={description} />
      </div>
    </div>
  );
};

export default Descriptions;
