import { InjectedIntl, injectIntl } from 'react-intl';
import Octicon, { Mute, Unmute } from '@primer/octicons-react';
import React, { useEffect, useRef } from 'react';
import appConfigs from 'appConfigs';

import ButtonNextSection from '@App/Components/ButtonNextSection';
import sectionsId from '@App/Constants/sections';
import Setting from '@App/Models/Setting';
import { getApi } from '@App/Services/api';

import styles from './homeSection.module.scss';

const {
  endpoint: {
    controllers: { settings },
  },
  settings: { homeVideoName },
} = appConfigs;

interface Props {
  intl: InjectedIntl;
}

const HomeSection: React.FC<Props> = ({ intl }) => {
  const [isMuted, setMute] = React.useState(true);
  const [videoSrc, setVideoSrc] = React.useState('');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect((): void => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = isMuted;
    }
    getApi<Setting>(`${settings}?name=${homeVideoName}`).then(
      (response): void => {
        if (response && response.value) {
          setVideoSrc(response.value);
        }
      }
    );
  }, []);

  const onVolumeClicked = (): void => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = !isMuted;
      setMute(!isMuted);
    }
  };

  return (
    <section id={sectionsId.homeSection} className={styles.wrapper}>
      {videoSrc && (
        <video preload="auto" autoPlay loop width="100%" ref={videoRef}>
          <track kind="captions" />
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
      <span
        role="presentation"
        className={styles.volume}
        onClick={onVolumeClicked}
      >
        <Octicon icon={isMuted ? Mute : Unmute} />
      </span>
      <ButtonNextSection
        title={intl.formatMessage({ id: 'navbar.story' })}
        idNextSection={sectionsId.storySection}
        className={styles.button}
        colorReverse
      />
    </section>
  );
};

export default injectIntl(HomeSection);
