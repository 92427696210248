import React from 'react';
import { Row } from 'reactstrap';

import ContactForm from './ContactForm';
import ContactDescription from './ContactDescription';
import styles from './contact.module.scss';

const Contact: React.FC = () => (
  <Row className={styles.wrapper}>
    <ContactForm />

    <ContactDescription />
  </Row>
);

export default Contact;
