import React from 'react';
import { Col, Spinner } from 'reactstrap';
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from 'react-intl';
import { Formik, FormikProps, FormikActions } from 'formik';
import appConfigs from 'appConfigs';

import { postApi } from '@App/Services/api';
import { getIcon } from '@App/Services/asset';

import formSchema from './formSchema';
import styles from './contactForm.module.scss';

interface Props {
  intl: InjectedIntl;
}
interface FormValue {
  message: string;
  file: File | null;
}

const { controllers } = appConfigs.endpoint;
const initialFormValues: FormValue = { message: '', file: null };

class ContactForm extends React.Component<Props> {
  private fileRef: React.RefObject<HTMLInputElement> = React.createRef();

  public state = { isLoading: false, notification: '' };

  private onSubmit = async (
    values: FormValue,
    actions: FormikActions<FormValue>
  ): Promise<void> => {
    const { message, file } = values;
    const { resetForm } = actions;

    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('message', message);
    file && formData.append('file', file);
    try {
      await postApi(controllers.contact, formData);
      resetForm();
      this.setState({ notification: 'contact.notify.submit.successful' });
    } catch {
      this.setState({ notification: 'contact.notify.submit.failed' });
    } finally {
      const timer = setTimeout((): void => {
        this.setState({ notification: '' });
        clearTimeout(timer);
      }, 3000);
      this.setState({ isLoading: false });
    }
  };

  private getFileName = (file: File | null): string | null =>
    (file && file.name) || null;

  public render(): React.ReactElement {
    const { isLoading, notification } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;

    const renderForm = (props: FormikProps<FormValue>): React.ReactElement => {
      const {
        values,
        handleSubmit,
        setFieldValue,
        handleChange,
        errors,
        touched,
        setFieldTouched,
        isValid,
      } = props;

      return (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles['form-input']}>
            <textarea
              className={styles.message}
              onFocus={(): void => {
                setFieldTouched('message', true);
              }}
              onBlur={(): void => {
                setFieldTouched('message', false);
              }}
              value={values.message}
              name="message"
              placeholder={formatMessage({
                id: 'contact.address.form.placeholder',
              })}
              onChange={handleChange}
            />

            <label className={styles['input-label']} htmlFor="attach-file">
              <img
                className={styles.image}
                src={getIcon('attach-file.svg')}
                alt="file"
              />
              <input
                id="attach-file"
                className={styles['input-file']}
                name="file"
                type="file"
                onChange={({ target: { files } }): void => {
                  setFieldValue('file', files && files[0]);
                }}
                ref={this.fileRef}
              />
              {`${(values.file && this.getFileName(values.file)) ||
                formatMessage({
                  id: 'contact.address.attachFile.text',
                })}`}
            </label>

            {values.file ? (
              <span
                role="presentation"
                className={`close-button ${styles['remove-file']}`}
                onClick={(): void => {
                  if (this.fileRef.current) {
                    this.fileRef.current.value = '';
                  }
                  setFieldValue('file', null);
                }}
              />
            ) : null}
          </div>

          {errors.file || errors.message || notification ? (
            <span className={styles['error-message']}>
              {formatMessage({
                id: errors.file || errors.message || notification,
              })}
            </span>
          ) : null}

          {touched.message || values.message ? (
            <span className={styles.provision}>
              <FormattedHTMLMessage id="contact.address.form.provision" />
            </span>
          ) : null}

          <button className={styles.button} type="submit" disabled={!isValid}>
            {formatMessage({
              id: `button.text.${isLoading ? 'sending' : 'send'}`,
            })}
            {isLoading ? <Spinner className={styles.spinner} /> : null}
          </button>
        </form>
      );
    };

    return (
      <Col md={6} sm={12} className={styles.wrapper}>
        <span className={styles.title}>
          {formatMessage({ id: 'contact.address.form.title' })}
        </span>

        <Formik
          initialValues={initialFormValues}
          onSubmit={this.onSubmit}
          validationSchema={formSchema}
          render={renderForm}
        />
      </Col>
    );
  }
}

export default injectIntl(ContactForm);
