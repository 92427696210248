import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import sectionsId from '@App/Constants/sections';
import { SectionContext } from '@App/Containers/Main';

import MethodAndStoryLayout from './MethodAndStoryLayout';

const StorySection: React.FC<{ intl: InjectedIntl }> = ({ intl }) => (
  <SectionContext.Consumer>
    {(context): React.ReactChild => (
      <MethodAndStoryLayout
        id={sectionsId.storySection}
        title={intl.formatMessage({ id: 'navbar.story' })}
        data={context.stories}
        nextSection={{
          id: sectionsId.innovationSection,
          title: intl.formatMessage({ id: 'navbar.innovation' }),
        }}
      />
    )}
  </SectionContext.Consumer>
);

export default injectIntl(StorySection);
