import React from 'react';

interface Props {
  children: React.ReactChild;
  className?: string;
}

const Layout: React.FC<Props> = ({ className = '', children }) => (
  <div className={className}>
    <div className="layout-max-width">{children}</div>
  </div>
);

export default Layout;
