import React from 'react';
import { Row, Col } from 'reactstrap';
import appConfigs from 'appConfigs';

import { SectionContext } from '@App/Containers/Main';

import Card from './Card';
import styles from './intelligenceCards.module.scss';

const gridConfigs = [
  { sm: 6, md: 4, lg: 3 },
  { sm: 6, md: 8, lg: 6 },
  { sm: 6, md: 4, lg: 3 },
  { sm: 6, md: 8, lg: 6 },
  { sm: 6, md: 8, lg: 6 },
  { sm: 6, md: 4, lg: 3 },
  { sm: 6, md: 8, lg: 6 },
];

const {
  domain,
  wwwroot: { imagesPath },
} = appConfigs.endpoint;

const IntelligenceCards: React.FC = () => {
  const [activation, setActive] = React.useState({
    cardId: -1,
    isActivated: false,
  });

  return (
    <Row className={styles.wrapper}>
      <SectionContext.Consumer>
        {(context): React.ReactChild[] =>
          context.intelligences.map(
            (i, index): React.ReactChild => (
              <Col
                {...gridConfigs[index]}
                key={i.title}
                className={styles.grid}
              >
                <Card
                  title={i.title}
                  imageSrc={`${domain}/${imagesPath}/${i.imageFileName}`}
                  description={i.description}
                  setActive={(isActivated: boolean): void => {
                    setActive({ cardId: index, isActivated });
                  }}
                  isActivated={
                    activation.cardId === index && activation.isActivated
                  }
                />
              </Col>
            )
          )
        }
      </SectionContext.Consumer>
    </Row>
  );
};

export default IntelligenceCards;
