import React from 'react';
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from 'react-intl';
import { Col } from 'reactstrap';

import history from '@App/History';
import routePaths from '@App/Constants/routePaths';

import { setLastScrollPosition } from '@App/Utils/storage';

import styles from './contact.module.scss';

interface Props {
  intl: InjectedIntl;
}

const ContactDescription: React.FC<Props> = ({ intl: { formatMessage } }) => (
  <Col md={6} sm={12} className={styles['contact-description-wrapper']}>
    <span className={styles.title}>
      {formatMessage({ id: 'contact.address.linkToBlog.text' })}
    </span>

    <button
      className={styles.button}
      type="button"
      onClick={(): void => {
        setLastScrollPosition();
        history.push(routePaths.blogs.path);
      }}
    >
      {formatMessage({ id: 'button.text.join' })}
    </button>

    <span className={`${styles.title} ${styles.sentence}`}>
      <FormattedHTMLMessage id="contact.address.sentenceDescription.text" />
    </span>
  </Col>
);

export default injectIntl(ContactDescription);
