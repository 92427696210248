import React, { useEffect } from 'react';
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';

import { getElementPosition, scrollToElement } from '@App/Utils/scroll';
import Layout from '@App/Components/Layout';
import { getImage } from '@App/Services/asset';
import headerConfigs from '@App/Components/NavigationBar/configs';
import history from '@App/History';
import routePaths from '@App/Constants/routePaths';
import sectionsId from '@App/Constants/sections';
import { setLastScrollPosition } from '@App/Utils/storage';
import ButtonHamberger from '@App/Components/ButtonHamberger';

import styles from './navigationBar.module.scss';
import LanguagesButton from './LanguagesButton';

interface SectionPositionType {
  id: string;
  position: number | null;
}

interface NavigationBarProps {
  isShownNavbarItems?: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  isShownNavbarItems = true,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { brand, navs } = headerConfigs;
  const [isCollapsed, setCollapse] = React.useState(false);
  const [isActivated, setActive] = React.useState('');
  const [isSmallBar, setSmallBar] = React.useState(false);

  useEffect((): (() => void) => {
    const sectionToTopDistance = 200;
    const firstSectionHeight = 600;

    const debounced = debounce((): void => {
      const sectionsPosition = headerConfigs.navs.map(
        (n): SectionPositionType => ({
          id: n.id,
          position: getElementPosition(n.id),
        })
      );

      const lastScrollY = window.pageYOffset;

      if (lastScrollY < firstSectionHeight) {
        setActive('');
        setSmallBar(false);

        return;
      }

      sectionsPosition.forEach(
        (s): void => {
          if (s.position && s.position - lastScrollY < sectionToTopDistance) {
            setActive(s.id);
            setSmallBar(true);
          }
        }
      );
    }, 100);

    const mouseDownListener = (event: MouseEvent): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isHambergerButton = (event.target as any).className.includes(
        'buttonHamberger'
      );

      const divRef = ref.current as HTMLDivElement;
      if (
        divRef &&
        !divRef.contains(event.target as Node) &&
        !isHambergerButton
      ) {
        setCollapse(false);
      }
    };

    window.addEventListener('scroll', debounced);
    window.addEventListener('mousedown', mouseDownListener);

    return (): void => {
      debounced.cancel();
      window.removeEventListener('scroll', debounced);
      window.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  const selectTab = (tab: string): void => {
    if (tab === sectionsId.blogSection) {
      setLastScrollPosition();
      history.push(routePaths.blogs.path);
    } else {
      scrollToElement(tab);
      setActive(tab);
    }
  };

  const onBrandClicked = (): void => {
    if (isShownNavbarItems) {
      scrollToElement(sectionsId.homeSection);
    } else {
      history.push(routePaths.main.path);
    }
  };

  return (
    <Layout className={styles.wrapper}>
      <Navbar
        expand="lg"
        className={`${styles['navbar-wrapper']} ${isSmallBar &&
          styles['small-bar']}`}
      >
        <NavbarBrand onClick={onBrandClicked}>
          <img
            alt={brand.alt}
            src={getImage(brand.image)}
            className={`${styles.logo} ${
              !isShownNavbarItems ? styles['align-logo'] : ''
            }`}
          />
        </NavbarBrand>

        {isShownNavbarItems ? (
          <React.Fragment>
            <ButtonHamberger
              onClick={(): void => setCollapse(!isCollapsed)}
              active={isCollapsed}
              className={styles['collapse-button']}
            />
            <Collapse
              isOpen={isCollapsed}
              navbar
              className={styles.collapse}
              innerRef={ref}
            >
              <Nav className={`ml-auto ${styles.list}`} navbar>
                {navs.map(
                  (item): React.ReactChild => (
                    <NavItem key={item.id} className={styles.item}>
                      <NavLink
                        className={`${styles['nav-title']} ${
                          isActivated === item.id ? styles.active : ''
                        }`}
                        onClick={(): void => selectTab(item.id)}
                        href={item.href}
                      >
                        <FormattedMessage id={item.title} />
                      </NavLink>
                    </NavItem>
                  )
                )}
                <NavItem className={styles['language-item']}>
                  <LanguagesButton />
                </NavItem>
              </Nav>
            </Collapse>
          </React.Fragment>
        ) : null}
      </Navbar>
    </Layout>
  );
};

export default NavigationBar;
