import React, { useEffect } from 'react';
import { getLocale, setLocale } from '@App/Utils/storage';

import headerConfigs from '../configs';
import styles from './languagesButton.module.scss';

const LanguagesButton: React.FC = () => {
  const [isToggle, setToggle] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { languages } = headerConfigs;

  useEffect((): (() => void) => {
    const mouseDownListener = (event: MouseEvent): void => {
      const divRef = ref.current as HTMLDivElement;
      if (divRef && !divRef.contains(event.target as Node)) {
        setToggle(false);
      }
    };
    window.addEventListener('mousedown', mouseDownListener);

    return (): void => {
      window.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  const switchLanguage = (language: string): void => {
    setLocale(language);
    window.location.reload();
  };

  return (
    <div
      role="presentation"
      className={styles.wrapper}
      onClick={(): void => {
        setToggle(!isToggle);
      }}
      ref={ref}
    >
      <span>{getLocale()}</span>
      <ul className={`${styles.list} ${isToggle ? styles.active : ''}`}>
        {languages.map(
          (item): React.ReactNode => (
            <li
              role="presentation"
              key={item.locale}
              onClick={(): void => {
                item.locale !== getLocale() && switchLanguage(item.locale);
              }}
              className={`${styles.item} ${
                item.locale === getLocale() ? styles.active : ''
              }`}
            >
              {item.title}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default LanguagesButton;
