import React from 'react';

import { scrollToElement } from '@App/Utils/scroll';

import styles from './buttonNextSection.module.scss';

interface Props {
  title: string;
  idNextSection: string;
  colorReverse?: boolean;
  className?: string;
}

const ButtonNextSection: React.FC<Props> = ({
  title,
  idNextSection,
  colorReverse,
  className = '',
}) => {
  const onClick = (): void => {
    scrollToElement(idNextSection);
  };

  return (
    <div
      role="presentation"
      className={`${styles.wrapper} ${className} ${
        colorReverse ? styles.reverse : ''
      }`}
      onClick={onClick}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.arrow}>
        <span className={styles.item} />
        <span className={styles.item} />
        <span className={styles.item} />
      </span>
    </div>
  );
};

export default ButtonNextSection;
