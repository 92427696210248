import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import Title from '@App/Components/Title';
import sectionsId from '@App/Constants/sections';

import Location from './Location';
import Contact from './Contact';
import styles from './contactSection.module.scss';

interface Props {
  intl: InjectedIntl;
}

const ContactSection: React.FC<Props> = ({ intl }) => {
  return (
    <section id={sectionsId.contactSection}>
      <Title title={intl.formatMessage({ id: 'navbar.contact' })} />

      <div className={styles.content}>
        <Location />

        <Contact />
      </div>
    </section>
  );
};

export default injectIntl(ContactSection);
