import { Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import appConfigs from 'appConfigs';

import LocationModel from '@App/Models/Location';
import { getListApi } from '@App/Services/api';

import styles from './location.module.scss';

interface Props {
  intl: InjectedIntl;
}
const { controllers } = appConfigs.endpoint;

const Location: React.FC<Props> = ({ intl: { formatMessage } }) => {
  const [locations, updateLocations] = React.useState<LocationModel[]>([]);

  useEffect((): void => {
    getListApi<LocationModel>(controllers.locations).then(
      (data): void => {
        updateLocations(data);
      }
    );
  }, []);

  return (
    <Row className={styles.wrapper}>
      {locations.map(
        (location): React.ReactChild => (
          <Col md={6} sm={12} key={location.id} className={styles.location}>
            <span className={styles.title}>{location.title}</span>

            {location.address ? (
              <a
                href={location.mapUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.text}
              >
                {location.address}
              </a>
            ) : (
              <span className={styles.text}>
                {formatMessage({ id: 'contact.address.text' })}
              </span>
            )}

            <a
              href={`mailto:${location.email}`}
              className={`${styles.text} ${styles.email}`}
            >
              {location.email}
            </a>
          </Col>
        )
      )}
    </Row>
  );
};
export default injectIntl(Location);
