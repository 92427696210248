import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-quill/dist/quill.snow.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import map from 'lodash.map';

import '@App/Styles/index.scss';
import i18n from '@App/I18n';
import history from '@App/History';
import routePaths from '@App/Constants/routePaths';
import { getLocale } from '@App/Utils/storage';

import { AppContextProvider } from './AppContext';
import * as serviceWorker from './serviceWorker';

const locale = getLocale();
// eslint-disable-next-line import/no-dynamic-require,@typescript-eslint/no-var-requires
const localeData = require(`react-intl/locale-data/${locale}`);
const messages: { [key: string]: { [key: string]: string } } = { ...i18n };

addLocaleData([...localeData]);

ReactDOM.render(
  <IntlProvider locale={locale} messages={messages[locale]}>
    <React.Fragment>
      <AppContextProvider>
        <Router history={history}>
          <Switch>
            {map(
              routePaths,
              (props, key): React.ReactChild =>
                props.redirect ? (
                  <Redirect key={key} to={props.path} />
                ) : (
                  <Route key={key} {...props} />
                )
            )}
          </Switch>
        </Router>
      </AppContextProvider>
    </React.Fragment>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
