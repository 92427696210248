import React from 'react';

import Descriptions from '@App/Components/Descriptions';
import { scrollToElement } from '@App/Utils/scroll';

import styles from './intelligenceCards.module.scss';

interface Props {
  title: string;
  imageSrc: string;
  description: string;
  isActivated: boolean;
  setActive: Function;
}

const Card: React.FC<Props> = ({
  title,
  imageSrc,
  description,
  isActivated,
  setActive,
}) => {
  const scrollToContainer = (): void => {
    const timer = setTimeout((): void => {
      scrollToElement(title, 80);
      clearTimeout(timer);
    }, 500);
  };

  return (
    <div
      id={title}
      className={`${styles.card} ${isActivated ? styles.active : ''}`}
    >
      <div
        role="presentation"
        style={{ backgroundImage: `url(${imageSrc})` }}
        className={styles.sumary}
        onClick={(): void => {
          setActive(true);
          scrollToContainer();
        }}
      >
        <span className={styles.title}>{title}</span>
      </div>

      <Descriptions
        title={title}
        description={description}
        onClose={(): void => {
          setActive(false);
        }}
        className={styles.description}
      />
    </div>
  );
};

export default Card;
