import appConfigs from 'appConfigs';

import { getLocale } from '@App/Utils/storage';

export type Value = string | number | boolean | [] | { [key: string]: Value };
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Data = FormData | Value | Value[];

const defaultOptions: RequestInit = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': '',
  },
  mode: 'cors',
  credentials: 'include',
};

const isAllowedValue = (value: Value): Value =>
  value || value === 0 || value === false;

const parseQueryToString = (object?: { [key: string]: Value }): string => {
  let result = '';

  if (object) {
    Object.keys(object).forEach(
      (key: string): void => {
        const value = object[key];

        if (isAllowedValue(value)) {
          if (value instanceof Array) {
            value.forEach(
              (v: Value): void => {
                result += `&${key}=${v}`;
              }
            );
          } else {
            result += `&${key}=${value}`;
          }
        }
      }
    );
  }

  return !result ? result : `?${result.slice(1, result.length)}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleResponse = async (response: Response): Promise<any> => {
  if (!response.ok) {
    throw response;
  }

  if (response.status !== 204) {
    try {
      return await response.json();
    } catch {
      // do something else
    }
  }

  return null;
};

const fetchApi = async (
  url: string,
  options?: {
    method?: Method;
    data?: Data;
    headers?: { [key: string]: string };
  }
): Promise<Response> => {
  const { domain, apiPath } = appConfigs.endpoint;
  const request: RequestInit = { ...defaultOptions };

  if (options) {
    const { method, data, headers } = options;
    request.method = method || 'GET';
    if (data) {
      if (data instanceof FormData) {
        request.headers = {};
        request.body = data;
      } else {
        request.body = JSON.stringify(data);
      }
    }
    if (headers) {
      request.headers = { ...request.headers, ...headers };
    }
  }

  return fetch(`${domain}/${apiPath}/${url}`, request);
};

interface PagingData<T> {
  total: number;
  data: T[];
}

export const getApi = async <T>(
  url: string,
  hasLanguage: boolean = true
): Promise<T | null> => {
  const response = await fetchApi(url, {
    headers: hasLanguage ? { 'Accept-Language': getLocale() } : undefined,
  });
  const result: T = await handleResponse(response);

  return result;
};

export const getListApi = async <T>(
  url: string,
  filters?: { [key: string]: Value },
  hasLanguage: boolean = true
): Promise<T[]> => {
  const query = parseQueryToString(filters);
  const list = await getApi<T[]>(`${url}${query}`, hasLanguage);

  if (list === null) {
    return [];
  }

  return list;
};

export const getPagingApi = async <T>(
  url: string,
  filters?: { [key: string]: Value },
  hasLanguage: boolean = true
): Promise<PagingData<T> | null> => {
  const query = parseQueryToString(filters);
  const response = await fetchApi(`${url}${query}`, {
    headers: hasLanguage ? { 'Accept-Language': getLocale() } : undefined,
  });

  const data: T[] = await handleResponse(response);

  if (data) {
    const contentRange = response.headers.get('Content-Range');
    if (contentRange) {
      const total = parseInt(contentRange.split('/')[1], 10);

      return { total, data };
    }
  }

  return null;
};

export const postApi = async <T>(
  url: string,
  data?: Data
): Promise<T | null> => {
  const response = await fetchApi(url, {
    method: 'POST',
    data,
  });
  const result: T = await handleResponse(response);

  return result;
};

export const putApi = async (url: string, data: Data): Promise<boolean> => {
  const response = await fetchApi(url, { method: 'PUT', data });

  return response.ok;
};

export const deleteApi = async (url: string): Promise<boolean> => {
  const response = await fetchApi(url, { method: 'DELETE' });

  return response.ok;
};
