import appConfigs from 'appConfigs';
import languages from '@App/Constants/languages';
import sectionsId from '@App/Constants/sections';

export default {
  brand: {
    image: 'logo-white.svg',
    alt: 'logo-2clicksolutions',
  },
  navs: [
    {
      id: sectionsId.storySection,
      title: 'navbar.story',
    },
    {
      id: sectionsId.innovationSection,
      title: 'navbar.innovation',
    },
    {
      id: sectionsId.intelligenceSection,
      title: 'navbar.intelligence',
    },
    {
      id: sectionsId.methodSection,
      title: 'navbar.method',
    },
    {
      id: sectionsId.contactSection,
      title: 'navbar.contact',
    },
    {
      id: sectionsId.jobsSection,
      title: 'navbar.jobs',
      href: appConfigs.jobsLink,
    },
    {
      id: sectionsId.blogSection,
      title: 'navbar.blog',
    },
  ],
  languages: [
    {
      locale: languages[languages.en],
      title: 'English',
    },
    {
      locale: languages[languages.it],
      title: 'Italian',
    },
    {
      locale: languages[languages.vi],
      title: 'Vietnamese',
    },
  ],
};
