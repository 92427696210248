/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, FC } from 'react';

interface ContextType {
  [key: string]: any;
  updateContext: (obj: { [key: string]: any }) => void;
}

const defaultValue: ContextType = {
  updateContext: (): void => {},
};

const AppContext = createContext<ContextType>(defaultValue);

export const AppContextProvider: FC = ({ children }) => {
  const [values, setValue] = useState<{ [key: string]: any }>({});

  const updateContext = (obj: { [key: string]: any }): void => {
    setValue({ ...values, ...obj });
  };

  return (
    <AppContext.Provider value={{ ...values, updateContext }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
