import Layout from '@App/Components/Layout';
import React from 'react';
import appConfigs from 'appConfigs';
import { getImage } from '@App/Services/asset';
import styles from './footer.module.scss';

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = ({ className = '' }) => (
  <Layout className={styles.layout}>
    <div className={`${styles.wrapper} ${className}`}>
      <span className={styles['copy-right']}>
        {`2CLICK SOLUTIONS ©${new Date().getFullYear()}`}
      </span>
      <div className={styles.socials}>
        <a
          href={appConfigs.socialLinks.facebook}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.item}
        >
          <img
            className={styles.logo}
            src={getImage('facebook.png')}
            alt="facebook"
          />
        </a>
        <a
          href={appConfigs.socialLinks.linkedin}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.item}
        >
          <img
            className={styles.logo}
            src={getImage('linkedin.png')}
            alt="linkedin"
          />
        </a>
      </div>
    </div>
  </Layout>
);

export default Footer;
