import React from 'react';
import { FormattedMessage } from 'react-intl';
import appConfigs from 'appConfigs';

import Descriptions from '@App/Components/Descriptions';
import { scrollToElement } from '@App/Utils/scroll';

import styles from './card.module.scss';
import { DataType, ControlsType } from '../interface';

interface Props {
  data: DataType;
  controls?: ControlsType;
}

const {
  domain,
  wwwroot: { imagesPath },
} = appConfigs.endpoint;

const Card: React.FC<Props> = ({ data, controls }) => {
  const [isActivated, setActive] = React.useState(false);

  if (controls && isActivated) {
    setActive(false);
  }

  const getActiveClass = (): string => {
    const isControlActive = (controls && controls.active) || isActivated;

    return (isControlActive && styles.active) || '';
  };

  const onToggle = (actived: boolean): void => {
    controls ? controls.onToggle(actived) : setActive(actived);
    const timer = setTimeout((): void => {
      actived && scrollToElement(data.title, 80);
      clearTimeout(timer);
    }, 500);
  };

  return (
    <div id={data.title} className={`${styles.wrapper} ${getActiveClass()}`}>
      <div className={`${styles.card}`}>
        <div
          className={`${styles.summary}`}
          role="presentation"
          onClick={(): void => {
            onToggle(true);
          }}
        >
          <div className={styles['image-wrapper']}>
            <img
              className={styles.image}
              src={`${domain}/${imagesPath}/${data.imageFileName}`}
              alt={data.title}
            />
          </div>
          <div className={styles['button-wrapper']}>
            <span className={styles.title}>{data.title}</span>
            <button
              className={styles.button}
              type="button"
              onClick={(): void => {
                onToggle(true);
              }}
            >
              <FormattedMessage id="button.text.seeMore" />
            </button>
          </div>
        </div>

        <Descriptions
          title={data.title}
          description={data.description}
          onClose={(): void => {
            onToggle(false);
          }}
          className={`${styles.description}`}
        />
      </div>
    </div>
  );
};

export default Card;
