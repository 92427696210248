import languages from '@App/Constants/languages';

const keys = {
  locale: 'locale',
  lastScrollPosition: 'pos',
  isloggedIn: 'isloggedIn',
};

export const setLocale = (locale: string): void => {
  localStorage.setItem(keys.locale, locale);
};

export const getLocale = (): string =>
  localStorage.getItem(keys.locale) || languages[languages.en];

export const setLastScrollPosition = (): void => {
  sessionStorage.setItem(
    keys.lastScrollPosition,
    window.pageYOffset.toString()
  );
};

export const getLastScrollPosition = (): number =>
  Number(sessionStorage.getItem(keys.lastScrollPosition)) || 0;

export const setLogInInfo = (isloggedIn: boolean): void => {
  localStorage.setItem(keys.isloggedIn, isloggedIn ? 'true' : 'false');
};

export const getLogInInfo = (): boolean => {
  const isLoggedIn = localStorage.getItem(keys.isloggedIn);

  return isLoggedIn === 'true' || false;
};
