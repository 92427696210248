import * as Yup from 'yup';

const typesAllowed = [
  'application/zip',
  'application/x-zip-compressed',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
const maxSize = 15 * 1024 * 1024;

const formSchema = Yup.object().shape({
  message: Yup.string()
    .max(3000, 'contact.notify.message.maxLength')
    .nullable(),
  file: Yup.mixed()
    .nullable()
    .test(
      'fileSize',
      'contact.notify.invalidFile',
      (value): boolean => (value ? value.size <= maxSize : true)
    )
    .test(
      'fileType',
      'contact.notify.invalidFile',
      (value): boolean => (value ? typesAllowed.includes(value.type) : true)
    ),
});

export default formSchema;
