import React from 'react';
import { Row, Col } from 'reactstrap';

import Card from './Card/Card';
import styles from './cardFlipping.module.scss';
import { DataType, ControlsType } from '../CardDropDown/interface';

interface Props {
  data: DataType[];
  controlled?: boolean;
}

const CardFlipping: React.FC<Props> = ({ data, controlled = false }) => {
  const [activation, setActive] = React.useState({ index: -1, status: false });
  const controls = (index: number): ControlsType | undefined => {
    if (!controlled) return undefined;

    return {
      active: activation.index === index && activation.status,
      onToggle: (status: boolean): void => {
        setActive({ index, status });
      },
    };
  };

  return (
    <Row className={styles.wrapper}>
      {data.map(
        (item, index): React.ReactChild => (
          <Col md={6} lg={4} key={item.title}>
            <Card data={item} controls={controls(index)} />
          </Col>
        )
      )}
    </Row>
  );
};

export default CardFlipping;
