import React, { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Main from '@App/Containers/Main';
import compose from '@App/HOCs/index';
import loginGuard from '@App/HOCs/loginGuard';
import lazyLoading from '@App/HOCs/lazyLoading';

const Blogs = React.lazy(
  (): Promise<{ default: ComponentType }> => import('@App/Containers/Blogs')
);

const Admin = React.lazy(
  (): Promise<{ default: ComponentType }> => import('@App/Containers/Admin')
);

const Login = React.lazy(
  (): Promise<{ default: ComponentType }> => import('@App/Containers/Login')
);

const configs: {
  [key: string]: {
    path: string;
    component: ComponentType | ComponentType<RouteComponentProps>;
    exact?: boolean;
    redirect?: boolean;
  };
} = {
  main: {
    path: '/',
    exact: true,
    component: Main,
  },
  blogs: {
    path: '/blogs',
    exact: true,
    component: lazyLoading(Blogs),
  },
  admin: {
    path: '/admin',
    component: compose(
      loginGuard,
      lazyLoading
    )(Admin),
  },
  login: {
    path: '/login',
    exact: true,
    component: lazyLoading(Login),
  },
  redirectMain: {
    path: '/',
    component: Main,
    redirect: true,
  },
};

export default configs;
