import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import ButtonNextSection from '@App/Components/ButtonNextSection';
import Title from '@App/Components/Title';
import sectionsId from '@App/Constants/sections';
import CardDropDown from '@App/Components/CardDropDown';
import { SectionContext } from '@App/Containers/Main';

import Partners from './Partners';

interface Props {
  intl: InjectedIntl;
}

const InnovationSection: React.FC<Props> = ({ intl }) => (
  <section id={sectionsId.innovationSection}>
    <Title
      title={intl.formatMessage({ id: 'navbar.innovation' })}
      colorReverse
    />

    <SectionContext.Consumer>
      {(context): React.ReactChild => (
        <CardDropDown data={context.innovations} />
      )}
    </SectionContext.Consumer>

    <Partners />

    <ButtonNextSection
      title={intl.formatMessage({ id: 'navbar.intelligence' })}
      idNextSection={sectionsId.intelligenceSection}
      colorReverse
    />
  </section>
);

export default injectIntl(InnovationSection);
