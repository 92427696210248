import React, { useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import { InjectedIntl, injectIntl } from 'react-intl';
import appConfigs from 'appConfigs';

import Partner from '@App/Models/Partner';
import { getListApi } from '@App/Services/api';

import CustomArrow from './CustomArrow';
import styles from './partners.module.scss';

interface Props {
  intl: InjectedIntl;
}
const { controllers } = appConfigs.endpoint;
const settings: Settings = {
  slidesToShow: 5,
  prevArrow: <CustomArrow />,
  nextArrow: <CustomArrow right />,
  responsive: [
    { breakpoint: 1200, settings: { slidesToShow: 4 } },
    { breakpoint: 992, settings: { slidesToShow: 3 } },
    { breakpoint: 768, settings: { slidesToShow: 2 } },
    { breakpoint: 576, settings: { slidesToShow: 1 } },
  ],
};

const Partners: React.FC<Props> = ({ intl }) => {
  const [partners, updatePartners] = React.useState<Partner[]>([]);
  const { domain, wwwroot } = appConfigs.endpoint;

  useEffect((): void => {
    getListApi<Partner>(controllers.partners).then(
      (data): void => {
        updatePartners(data);
      }
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        {intl.formatMessage({ id: 'innovation.title.partners' })}
      </span>

      <Slider
        {...settings}
        slidesToShow={
          partners.length < Number(settings.slidesToShow)
            ? partners.length
            : settings.slidesToShow
        }
        className={styles['custom-slider']}
      >
        {partners.map(
          (p): React.ReactChild => (
            <a
              href={p.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={p.name}
              className={styles.item}
            >
              <img
                className={styles.image}
                src={`${domain}/${wwwroot.imagesPath}/${p.imageFileName}`}
                alt={p.name}
                title={p.name}
              />
            </a>
          )
        )}
      </Slider>
    </div>
  );
};

export default injectIntl(Partners);
