import React, { useEffect, ComponentType } from 'react';
import appConfigs from 'appConfigs';

import { getApi } from '@App/Services/api';
import history from '@App/History';
import routePaths from '@App/Constants/routePaths';
import { setLogInInfo as storeLogInInfo } from '@App/Utils/storage';

import withLogInInfo, { ContextLoginInfo } from './withLogInInfo';

const loginGuard = (Component: ComponentType): ComponentType => {
  const LoginGuardedComponent: React.FC<ContextLoginInfo> = props => {
    const { isLoggedIn, userEmail, setLogInInfo } = props;

    useEffect((): void => {
      if (!isLoggedIn || !userEmail) {
        getApi<{ email: string }>(`${appConfigs.endpoint.controllers.auth}/get`)
          .then(
            (response): void => {
              storeLogInInfo(true);
              setLogInInfo({
                isLoggedIn: true,
                userEmail: response && response.email ? response.email : null,
              });
            }
          )
          .catch(
            (): void => {
              storeLogInInfo(false);
              setLogInInfo({
                isLoggedIn: false,
                userEmail: null,
              });
              history.push(routePaths.login.path);
            }
          );
      }
    }, []);

    return isLoggedIn ? <Component {...props} /> : null;
  };

  return withLogInInfo(LoginGuardedComponent);
};

export default loginGuard;
