import React, { useEffect } from 'react';

import ButtonNextSection from '@App/Components/ButtonNextSection';
import CardFlipping from '@App/Components/CardFlipping';
import CardDropDown from '@App/Components/CardDropDown';
import Title from '@App/Components/Title';
import Section from '@App/Models/Section';

interface Props {
  id: string;
  title: string;
  data: Section[];
  nextSection: {
    id: string;
    title: string;
  };
  controlled?: boolean;
}

const MethodAndStoryLayout: React.FC<Props> = ({
  id,
  title,
  data,
  nextSection,
  controlled = false,
}) => {
  const [isMobileView, setMobileView] = React.useState(false);

  useEffect((): (() => void) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const listener = (): void => {
      if (mediaQuery.matches) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    listener();

    mediaQuery.addListener(listener);

    return (): void => {
      mediaQuery.removeListener(listener);
    };
  }, []);

  return (
    <section id={id}>
      <Title title={title} />

      {isMobileView ? (
        <CardDropDown data={data} reverseColor />
      ) : (
        <CardFlipping data={data} controlled={controlled} />
      )}

      <ButtonNextSection
        title={nextSection.title}
        idNextSection={nextSection.id}
      />
    </section>
  );
};

export default MethodAndStoryLayout;
