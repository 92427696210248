import { ComponentType } from 'react';

type HOC = (Component: any) => ComponentType; // eslint-disable-line @typescript-eslint/no-explicit-any

const compose = (...hocs: HOC[]): HOC => (
  Component: ComponentType
): ComponentType =>
  hocs.reduceRight(
    (previous, composer): ComponentType => composer(previous),
    Component
  );

export default compose;
