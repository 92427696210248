export default {
  homeSection: 'home-section',
  storySection: 'story-section',
  innovationSection: 'innovation-section',
  intelligenceSection: 'intelligence-section',
  methodSection: 'method-section',
  contactSection: 'contact-section',
  jobsSection: 'jobs-section',
  blogSection: 'blog-section',
};
