import React from 'react';
import { Row, Col } from 'reactstrap';

import Card from './Card/Card';
import styles from './cardDropDown.module.scss';
import { DataType, ControlsType } from './interface';

interface Props {
  data: DataType[];
  reverseColor?: boolean;
  controlled?: boolean;
}

const FlipCard: React.FC<Props> = ({ data, reverseColor = false }) => {
  const [activation, setActive] = React.useState({ index: -1, status: false });
  const controls = (index: number): ControlsType | undefined => ({
    active: activation.index === index && activation.status,
    onToggle: (status: boolean): void => {
      setActive({ index, status });
    },
  });

  return (
    <Row className={styles.wrapper}>
      {data.map(
        (item, index): React.ReactChild => (
          <Col sm={6} lg={4} key={item.title}>
            <Card
              data={item}
              controls={controls(index)}
              reverseColor={reverseColor}
            />
          </Col>
        )
      )}
    </Row>
  );
};

export default FlipCard;
