import React from 'react';

import styles from './partners.module.scss';

interface Props {
  currentSlide?: number;
  slideCount?: number;
  className?: string;
  right?: boolean;
}

const CustomArrow: React.FC<Props> = ({
  currentSlide,
  slideCount,
  className,
  right = false,
  ...props
}) => (
  <div
    className={`${className} ${styles.arrow} ${
      right ? styles.right : styles.left
    }`}
    {...props}
  />
);

export default CustomArrow;
