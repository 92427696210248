import React, { ReactElement, ComponentType } from 'react';

import AppContext from '../AppContext';

export interface ContextLoginInfo {
  isLoggedIn: boolean;
  userEmail: string | null;
  setLogInInfo: (values: {
    isLoggedIn: boolean;
    userEmail: string | null;
  }) => void;
}

const withLogInInfo = (
  Component: ComponentType<ContextLoginInfo>
): ComponentType => {
  return (props): ReactElement => (
    <AppContext.Consumer>
      {(context): ReactElement => (
        <Component
          {...props}
          isLoggedIn={context.isLoggedIn || false}
          userEmail={context.userEmail || null}
          setLogInInfo={context.updateContext}
        />
      )}
    </AppContext.Consumer>
  );
};

export default withLogInInfo;
